import React, { Component } from 'react';
import lancamentoDataService from "../services/lancamento.service";
import contasDataService from "../services/conta.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, capitalizeFirstLetter, getReferenciaMonth, getReferenciaYear } from '../utils';
import {  toast } from 'react-toastify';

const handleButtonClick = (state) => {
  window.location = "/editar-lancamento";
};

class contas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lancamentos: [],
      contas: [],
      total: 0,
      totalpago: 0,
      totalpendente: 0,
      saldototal: 0,
      DiaPrincipal: new Date(),
      DiaFiltro: getReferenciaYear() === null ? new Date() : new Date(getReferenciaYear(), getReferenciaMonth(), '01'),

      AgendaMes: []
      , filtro: 'mensal'
      , mark: []
      , AgendaFiltro: []
      , Mensagem: "Carregando lancamentos"
    };

  }

  handleLogout = () => {

  }

  nextDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = (this.state.filtro === 'mensal' ? addMonths(this.state.DiaFiltro, parseInt(pDay)) : this.state.filtro === 'semanal' ? addWeeks(addDays(this.state.DiaPrincipal, 3), parseInt(pDay)) : addDays(this.state.DiaFiltro, parseInt(pDay)))
    if (pDay !== null) {
      this.setState({
        DiaPrincipal: newDay
      })
    }

    this.onClickDay(newDay);
  }
  handleLancamentoClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/editar-lancamento/" + index;
  }
  handleSaldoClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/editar-saldo/" + index;
  }
  handleNovoLancamento = () => {
    window.location = APP_URL() + "lancamento/novo";
  }
  clickDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = new Date(pDay);

    if ((pDay !== null)) {
      this.filtraAgenda(newDay.getDate(), newDay.getMonth(), newDay.getFullYear());
      if (this.state.filtro !== 'semanal') {
        this.setState({
          DiaPrincipal: newDay
        })
      }
    }
  }

  onClickDay(event) {
    this.filtraAgenda('01', event.getMonth(), event.getFullYear());
    // localStorage.setItem('Referencia-day', event.getDate());
    localStorage.setItem('Referencia-month', event.getMonth());
    localStorage.setItem('Referencia-year', event.getFullYear());
  }

  filtraAgenda(day, month, year) {
    let pDiaFiltro = new Date(year, month, day);
    let pDiaFiltroCurrent = this.state.DiaFiltro;

    this.setState({
      AgendaFiltro: this.state.AgendaMes.filter(
        filtro => new Date(filtro.DataAgendamento).getDate() === pDiaFiltro.getDate()
          && new Date(filtro.DataAgendamento).getMonth() === pDiaFiltro.getMonth()
          && new Date(filtro.DataAgendamento).getFullYear() === pDiaFiltro.getFullYear()),
      DiaFiltro: pDiaFiltro
    });

    if (this.state.filtro !== 'semanal') {
      this.setState({
        DiaPrincipal: pDiaFiltro
      })
    }
    if (pDiaFiltroCurrent.getMonth() !== pDiaFiltro.getMonth() || pDiaFiltroCurrent.getFullYear() !== pDiaFiltro.getFullYear()) {

      this.setState({
        AgendaMes: [],
        mark: []

      })
      this.getlancamentos(pDiaFiltro);
      this.getsaldos(pDiaFiltro);
    }
  }

  setFiltro = filter => {
    this.setState({ filtro: filter === 0 ? 'mensal' : (filter === 1 ? 'semanal' : 'diario') })

  }
  componentDidMount() {

    this.getContas();

  }


  getContas() {

    contasDataService.getAll()
      .then(response => {
        this.setState({
          contas: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });

      })
      .catch(e => {
        //console.log(e);
      });
  }



  render() {


    return (

      <div className="page-wrapper" >

        <NavTop></NavTop>
        <NavMain></NavMain>
        {/* <button onClick={notify}>Notify!</button> */}
        <Header title="contas..." fluid={true}></Header>
        <section className="course-one course-one__teacher-details home-one">

          <Container fluid={false}>
            <Row className={""}>
              <Col className={"py-2 "}>
                <Link to="/editar-conta" className="btn pull-right btn-info botao-acao mb-4" >+</Link>
              </Col>
            </Row>
            <Row>
              <Col className=''>
                <Table striped bordered hover size="sm" className='small'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Descrição</th>
                      <th>Caixinha</th>
                      <th>Secreta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.contas.map(data =>
                        <tr key={data.id}>
                          <td><Link onClick={() => this.handleSaldoClick(data.id)}> <i className="material-icons p-2" data-key={-1}>mode_edit</i></Link></td>
                          <td>{data.descricao}</td>
                          <td>{data.caixinha}</td>
                          <td>{data.secreta}</td>

                          {/* <td><NumberFormat className="text-right d-block" value={parseFloat(data.saldo)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td> */}
                        </tr>
                      )
                    }
                  </tbody>

                </Table>
              </Col>
            </Row>
          </Container>
        </section>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default contas;
