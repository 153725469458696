import React, { PureComponent } from 'react';
import categoriaDataService from "../services/categoria.service";
import { Line } from 'react-chartjs-2';
import NavMain from './Common/NavMain';
import { APP_URL, getReferenciaMonth, getReferenciaYear } from '../utils';
import NavTop from './Common/NavTop';
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import { Chart as ChartJS, CategoryScale, LinearScale, ArcElement, PointElement,BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, ArcElement,  BarElement, LineElement, Title, Tooltip, Legend);

class CategoriaRelatorio extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      descricao: '',
      categorias: [],
      saldos: [],
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: this.props.descricao,
          },
        },
      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            data: []
          }
        ]
      },
      total: 0,
      totalpago: 0,
      totalpendente: 0,
      saldototal: 0,
      DiaPrincipal: new Date(),
      DiaFiltro: getReferenciaYear() === null ? new Date() : new Date(getReferenciaYear(), getReferenciaMonth(), '01'),

      AgendaMes: []
      , filtro: 'mensal'
      , mark: []
      , AgendaFiltro: []
      , Mensagem: "Carregando categorias"
    };

  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let categoria = query.has('id') ? query.get('id') : (this.props.match.params.id !== undefined ? this.props.match.params.id : '-');

    this.getcategoria(categoria);

  }



  getcategoria(categoria) {

    categoriaDataService.getHistorico(categoria)

      .then(res => res.data)
      .then((jsonarray) => {

        var labels = jsonarray.map(function (e) {
          return e.referencia.substring(0, 7);
        });
        var dataValor = jsonarray.map(function (e) {
          return e.valor;
        });
        var dataValorPago = jsonarray.map(function (e) {
          return e.valorPago;
        });

        console.log(jsonarray);

        this.setState({
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top'
              },
              title: {
                display: true,
                text: jsonarray[0].descricao,
              },
            },
          },
          chartData: {
            labels: labels,
            datasets: [
              {
                label: 'valor',
                data: dataValor,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
              {
                label: 'valor Pago',
                data: dataValorPago,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              }
            ]
          }
        })
      })

      .catch(e => {
        console.log(e);
      });
  }


  render() {


    return (
      <div className="page-wrapper" >
        {/* {console.log(data)} */}
        {/* {console.log(this.props.chartData)} */}
        {/* {console.log(this.state.chartData)} */}
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Container>
          <Row>
            <Col>
              <Line options={this.state.options} data={this.state.chartData} />
            </Col>
          </Row>
        </Container>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default CategoriaRelatorio;
