import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import UsuarioDataService from "../services/usuario.service";
import saldoDataService from "../services/saldo.service";
import categoriaDataService from "../services/categoria.service";
import FooterMenu from './Common/FooterMenu';
import { Col, Container, Row } from 'react-bootstrap';
import { Line, Bar, Chart } from 'react-chartjs-2';
import { APP_URL, capitalizeFirstLetter, getReferenciaMonth, getReferenciaYear, showMessageConfirm, showMessagedefault, showMessageError, showMessageInfo, showMessageSuccess, showMessageWarn } from '../utils';
export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {

      categorias: [],
      saldos: [],
      DiaFiltro: getReferenciaYear() === null ? new Date() : new Date(getReferenciaYear(), getReferenciaMonth(), '01'),
      optionsLine: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: this.props.descricao,
          },
        },
      },
     
      optionsChart: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: this.props.descricao,
          },
        },
      },
      chartDataLine: {
        labels:[],
        datasets: [
          {
            label: '',
            data: []
          }
        ]
      },
     
      chartDataChart: {
        labels: [],
        datasets: [
          {
            label: '',
            data: []
          }
        ]
      }
    };

  }
  componentDidMount() {
    this.getcategorialine();
    
    this.getcategoriachart();
  }

 
  getcategorialine() {

    saldoDataService.getSaldoProjecao()

      .then(res => res.data)
      .then((jsonarray) => {

        var labels = jsonarray.map(function (e) {
          return e.referenciaDate.substring(0, 7);
        });


 
      var dataSaldo = jsonarray.map(function (e) {return e.Saldo;});
      var dataSaldoPendente = jsonarray.map(function (e) {return e.SaldoPendente;});
      var dataTotalEntrada = jsonarray.map(function (e) {return e.TotalEntrada;});
      var dataAreceber = jsonarray.map(function (e) {return e.Areceber;});
      var dataRecebido = jsonarray.map(function (e) {return e.Recebido;});
      var dataTotalSaida = jsonarray.map(function (e) {return e.TotalSaida;});
      var dataApagar = jsonarray.map(function (e) {return e.Apagar;});
      var dataPago = jsonarray.map(function (e) {return e.Pago;});         

        console.log(jsonarray);

        this.setState({
          optionsLine: {
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            stacked: true,
            plugins: {
              legend: {
                position: 'top'
              },
              title: {
                display: true,
                text: 'Projeção'//jsonarray[0].descricao,
              },
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
              },
              y1: {
                type: 'linear',
                display: false,
                position: 'right',
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
          },
          chartDataLine: {
            labels: labels,
            datasets: [
              {
                label: 'Total Entradas',
                data: dataTotalEntrada,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y',
              },
              {
                label: 'Valor à receber',
                data: dataAreceber,
                borderColor: 'rgb(53, 162, 235, 0.2)',
                backgroundColor: 'rgba(53, 162, 235, 0.2)',
                yAxisID: 'y',
              },
              {
                label: 'Valor recebido',
                data: dataRecebido,
                borderColor: 'rgb(53, 162, 235, 0.2)',
                backgroundColor: 'rgba(53, 162, 235, 0.2)',
                yAxisID: 'y',
              },
              {
                label: 'Total Saidas',
                data: dataTotalSaida,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',     
                yAxisID: 'y',        
              },
              {
                label: 'Total à pagar',
                data: dataApagar,
                borderColor: 'rgb(255, 99, 132, 0.2)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',   
                yAxisID: 'y',                      
              },
              {
                label: 'Total pago',
                data: dataPago,
                borderColor: 'rgb(255, 99, 132, 0.2)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',     
                yAxisID: 'y',          
              },
              {
                label: 'Saldo',
                data: dataSaldo,
                borderColor: 'rgb(99, 255 ,111, 0.5)',
                backgroundColor: 'rgba(99, 255 ,111, 0.5)',
                yAxisID: 'y',        
              },
              {
                label: 'Saldo Pendente',
                data: dataSaldoPendente,
                borderColor: 'rgb(99, 255 ,111, 0.2)',
                backgroundColor: 'rgba(99, 255 ,111, 0.2)',
                yAxisID: 'y',        
              }
              
            ]
          }
        })
      })

      .catch(e => {
        console.log(e);
      });
  }
  getcategoriachart() {
    // let pDiaFiltro = new Date(year, month, day);
    let pDiaFiltroCurrent = this.state.DiaFiltro;
    
    let hoje = new Date(pDiaFiltroCurrent === undefined ? this.state.DiaFiltro : pDiaFiltroCurrent);
    saldoDataService.getSaldoProjecao()

      .then(res => res.data)
      .then((jsonarray) => {

        var labels = jsonarray.map(function (e) {
          return e.referenciaDate.substring(0, 7);
        });


 
      var dataSaldo = jsonarray.map(function (e) {return e.Saldo;});
      var dataSaldoPendente = jsonarray.map(function (e) {return e.SaldoPendente;});
      var dataTotalEntrada = jsonarray.map(function (e) {return e.TotalEntrada;});
      var dataAreceber = jsonarray.map(function (e) {return e.Areceber;});
      var dataRecebido = jsonarray.map(function (e) {return e.Recebido;});
      var dataTotalSaida = jsonarray.map(function (e) {return -e.TotalSaida;});
      var dataApagar = jsonarray.map(function (e) {return e.Apagar;});
      var dataPago = jsonarray.map(function (e) {return e.Pago;});         
      var dataTotalBalanco = jsonarray.map(function (e) {return e.TotalEntrada-  e.TotalSaida;});

        console.log(jsonarray);

        this.setState({
          optionsChart: {
            responsive: true,    
            interaction: {
              mode: 'index',
              intersect: false,
            },
            stacked: true,
            plugins: {
              legend: {
                position: 'top'
              },
              title: {
                display: true,
                text: 'Projeção'//jsonarray[0].descricao,
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
              // y: {
              //   type: 'linear',
              //   display: true,
              //   position: 'left',
              // },
              // y1: {
              //   type: 'linear',
              //   display: false,
              //   position: 'right',
              //   grid: {
              //     drawOnChartArea: false,
              //   },
              // },
            },
          },
          chartDataChart: {
            labels: labels,
            datasets: [
              {
                label: 'Total Entradas',
                type: 'bar',
                data: dataTotalEntrada,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                // borderColor: 'rgb(53, 162, 235)',
                // yAxisID: 'y',
              },
              // {
              //   label: 'Valor à receber',
              //   data: dataAreceber,
              //   backgroundColor: 'rgba(53, 162, 235, 0.2)',
              //   borderColor: 'rgb(53, 162, 235, 0.2)',
              //   yAxisID: 'y',
              // },
              // {
              //   label: 'Valor recebido',
              //   data: dataRecebido,
              //   backgroundColor: 'rgba(53, 162, 235, 0.2)',
              //   borderColor: 'rgb(53, 162, 235, 0.2)',
              //   yAxisID: 'y',
              // },
              {
                label: 'Total Saidas',
                type: 'bar',
                data: dataTotalSaida,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',     
                // borderColor: 'rgb(255, 99, 132)',
                // yAxisID: 'y',        
              },
              // {
              //   label: 'Total à pagar',
              //   data: dataApagar,
              //   backgroundColor: 'rgba(255, 99, 132, 0.2)',   
              //   // borderColor: 'rgb(255, 99, 132, 0.2)',
              //   // yAxisID: 'y',                      
              // },
              // {
              //   label: 'Total pago',
              //   data: dataPago,
              //   backgroundColor: 'rgba(255, 99, 132, 0.2)',     
              //   borderColor: 'rgb(255, 99, 132, 0.2)',
              //   // yAxisID: 'y',          
              // },
              // {
              //   label: 'Saldo',
              //   type: 'line', 
              //   data: dataSaldo,
              //   backgroundColor: 'rgba(99, 255 ,111, 0.5)',
              //   // borderColor: 'rgb(99, 255 ,111, 0.5)',
              //   // yAxisID: 'y',        
              // },
              // {
              //   label: 'Saldo Pendente',
              //   type: 'line',
              //   data: dataSaldoPendente,
              //   backgroundColor: 'rgba(99, 255 ,111, 0.2)',
              //   // borderColor: 'rgb(99, 255 ,111, 0.2)',
              // //   yAxisID: 'y',        
              // },
              {
                label: 'Balanço',
                type: 'line',
                data: dataTotalBalanco,
                backgroundColor: 'rgba(99, 255 ,111, 0.2)',
                // borderColor: 'rgb(99, 255 ,111, 0.2)',
                // yAxisID: 'y',        
              }
            ]
          }
        })
      })

      .catch(e => {
        console.log(e);
      });
  }


  render() {

    return (
      <div className="">
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Home" ></Header>
        <Container>
          <Row>
            <Col>
              Seja bem-vindo ao meu financeiro
            </Col>
          </Row>

          <Row>
            <Col>
              <Line options={this.state.optionsLine} data={this.state.chartDataLine} />
            </Col>
        
          </Row>
          <Row>
          <Col >           
            <Chart  type='bar' options={this.state.optionsChart}  data={this.state.chartDataChart} />           
          </Col>
         
          </Row>
          
        </Container>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div>
    );
  }

}

