
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getEmail, isLogin } from '../../utils';


class NavTop extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {
        return (

            <div className="topbar-one">
                <ToastContainer
                    enableMultiContainer
                    containerId={'*'}
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="container-fluid">
                    <div className="topbar-one__left font-weight-bold">
                        <p className="site-footer__copy font-size-14 lh-14 py-3">Meu financeiro {getEmail() !== '' && getEmail() !== null ? '  - Logado como: ' + getEmail() : ''}</p>

                        {/* <Link to="#">(041) 3333.3333</Link> */}
                    </div>{/*<!-- /.topbar-one__left -->*/}
                    <div className="topbar-one__right">
                        {isLogin()
                            ?
                            <div className="topbar-one__left font-weight-bold">
                                {/* <Link to="#">{getEmail()}</Link> */}
                            </div>
                            :
                            <>
                                {/* <Link to="login">Entrar</Link> */}
                                {/* <Link to="#">Cadastrar</Link> */}
                            </>
                        }
                    </div>{/*<!-- /.topbar-one__right -->*/}
                </div>{/*<!-- /.container -->*/}
            </div>

        );
    }
}
export default NavTop;              