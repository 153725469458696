import React, { Component } from 'react';
// import HeaderAndFooter from '../Perfil/HeaderAndFooter';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
// import Header from './Header';
import NavMain from './NavMain';
import NavTop from './NavTop';
export default class GenericNotFound extends Component {


  constructor(props) {
    super(props);

    this.state = {
      titulo: ""
      , navbar: false
      , sidebar: true
      , navigation: true
    }

  }



  render() {

    return (

      <div className="home">


        <div className="page-wrapper" >
          <NavTop></NavTop>
          <NavMain></NavMain>
          {/* <Header title="404" ></Header> */}
          <Container fluid={false} className="  mb-3 ">
            <Row className=" my-5  m-0 p-0">
              <Col xs={12} className="">
                <h1 >404</h1>
              </Col>

              <Col xs={12} className="text-left">
                <Link className="btn btn-right " to="./">Ir para HOME</Link>
              </Col>
            </Row>
          </Container>
          <FooterMenu></FooterMenu>
          <Footer></Footer>
        </div >
      </div>
    );
  }
}
