import React from "react";

import InputMask from "react-input-mask";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//  import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
// import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
// import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
// import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';

export const TextInput = props => {
    const { id, type = "text", name, placeholder, required, className = "form-group", validate,
        labelValue, disabled, readonly, maxLength, onChange, autocomplete = 'off',
        value, defaultValue, onBlur, min, max, MsgErro, maskPlaceholder, mask, visible = "true",
        labelClass = "", formGroupClass = "", step="1"} = props;
    //const { onChange, ...restEvents } = events;
    //const { contClass, fieldClass, errorClass } = classes;
    //const { setFields, addField, fields, validateField, errors } = useContext(
    //    Form
    //);
    //const fieldError = errors[id];
    const style = {
        display: visible === "true" ? "block" : "none"
    }
    //  console.log(name);
    return (

        type === 'hidden'
            ?

            <input
                id={id}
                name={name}
                type={type}
                // value={value}
                defaultValue={defaultValue !== null ? defaultValue : ""}
                readOnly={true}
            />
            : type === 'ckeditor'
                ?
                <div className={"form-group " + formGroupClass + " withoutmask"}>
                    {
                        labelValue === undefined || visible === "false" ? "" : (
                            <label htmlFor={id} className={labelClass}>{labelValue}</label>
                        )
                    }
                    <CKEditor
                        id={id}
                        editor={ClassicEditor}
                        locale={"pt-BR"}
                        config={{
                            // plugins: [ Bold, Italic, Underline, Strikethrough, Code, Subscript, Superscript ],
                            toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'Strikethrough', 'superscript', 'subscript', 'Code', 'fontColor', 'fontBackgroundColor', 'undo', 'redo', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                            //  plugins:  [ Font, Essentials, Paragraph, Bold, Italic  ],
                            //  toolbar: [ 'bold', 'italic' ]
                            //  toolbar: [ 'Bold', 'Italic' ]
                            // plugins: [   ],
                            //  toolbar: [ 'bold', 'italic' ],
                            // fontColor: {
                            //     colors: [
                            //         {
                            //             color: 'hsl(0, 0%, 0%)',
                            //             label: 'Black'
                            //         },
                            //         {
                            //             color: 'hsl(0, 0%, 30%)',
                            //             label: 'Dim grey'
                            //         },
                            //         {
                            //             color: 'hsl(0, 0%, 60%)',
                            //             label: 'Grey'
                            //         },
                            //         {
                            //             color: 'hsl(0, 0%, 90%)',
                            //             label: 'Light grey'
                            //         },
                            //         {
                            //             color: 'hsl(0, 0%, 100%)',
                            //             label: 'White',
                            //             hasBorder: true
                            //         },

                            //         // ...
                            //     ]
                            // },
                            // fontBackgroundColor: {
                            //     colors: [
                            //         {
                            //             color: 'hsl(0, 75%, 60%)',
                            //             label: 'Red'
                            //         },
                            //         {
                            //             color: 'hsl(30, 75%, 60%)',
                            //             label: 'Orange'
                            //         },
                            //         {
                            //             color: 'hsl(60, 75%, 60%)',
                            //             label: 'Yellow'
                            //         },
                            //         {
                            //             color: 'hsl(90, 75%, 60%)',
                            //             label: 'Light green'
                            //         },
                            //         {
                            //             color: 'hsl(120, 75%, 60%)',
                            //             label: 'Green'
                            //         },

                            //         // ...
                            //     ]
                            // },
                            // heading: {
                            //     options: [
                            //         { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            //         { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            //         { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                            //     ]
                            // }


                        }}
                        data={
                            value !== null ? value : ""
                        }

                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            //console.log('Editor is ready to use!', editor);
                        }}
                        onChange={onChange}
                    //  onChange={(event, editor) => {
                    //      const data = editor.getData();
                    //      //console.log({ event, editor, data });
                    //      onChange(data);
                    //  }}
                    // onBlur={(event, editor) => {
                    //     //console.log('Blur.', editor);

                    // }}
                    // onFocus={(event, editor) => {
                    //     //console.log('Focus.', editor);
                    // }}
                    />
                </div>
                :
                mask === undefined
                    ?
                    <div className={"form-group " + formGroupClass + " withoutmask"}>
                        {
                            labelValue === undefined || visible === "false" ? "" : (
                                <label htmlFor={id} className={labelClass}>{labelValue}</label>
                            )
                        }
                        <input
                            id={id}
                            name={name}
                            type={type}
                            min={min}
                            max={max}
                            step={step}
                            autoComplete={autocomplete}
                            required={required}
                            validade={validate}
                            className={className}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            onChange={onChange}
                            value={ value !== null ? value : ""}
                            // defaultValue={defaultValue !== null ? defaultValue : ""}
                            defaultValue={value === undefined ?defaultValue !== null ? defaultValue : "": undefined}
                            disabled={disabled}
                            readOnly={readonly}
                            visible={visible}
                            onBlur={onBlur}
                            style={style}
                        />
                        {(MsgErro !== undefined && MsgErro !== "") ? <p className="erro">{MsgErro}</p> : ""}
                    </div>
                    :
                    <div className={"form-group " + formGroupClass + " mask"}>

                        {
                            labelValue === undefined || visible === "false" ? "" : (
                                <label htmlFor={id} className={labelClass}>{labelValue} </label>
                            )
                        }
                        <InputMask
                            id={id}
                            name={name}
                            type={type}
                            autoComplete={autocomplete}
                            required={required}
                            validade={validate}
                            className={className}
                            mask={mask}
                            maskPlaceholder={maskPlaceholder}
                            maxLength={maxLength}
                            onChange={onChange}
                            value={ value !== null ? value : ""}
                            min={min}
                            max={max}
                            defaultValue={ defaultValue !== null ? defaultValue : ""}
                            disabled={disabled}
                            readOnly={readonly}
                            visible={visible}
                            onBlur={onBlur}
                            style={style}
                        />
                        {MsgErro !== "" ? <p className="erro">{MsgErro}</p> : ""}
                    </div>
    );
}
export default TextInput