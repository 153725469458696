import React, { Component } from 'react';
import lancamentoDataService from "../services/lancamento.service";
import saldoDataService from "../services/saldo.service";
import { isAfter, isBefore, areEqual, isSameDay } from "date-fns"
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, capitalizeFirstLetter, getReferenciaMonth, getReferenciaYear, showMessageConfirm, showMessagedefault, showMessageError, showMessageInfo, showMessageSuccess, showMessageWarn } from '../utils';
import { toast, ToastContainer } from 'react-toastify';
import { Line, Bar, Chart } from 'react-chartjs-2';

const handleButtonClick = (state) => {
  window.location = "/editar-lancamento";
};
class lancamentos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lancamentos: [],
      saldos: [],
      totalentrada:0,    
      totalareceber:0,
      totalrecebido:0,
      totalsaida:0,
      totalapagar:0,
      totalpago:0,
      total: 0,
      totalpendente: 0,
      saldototal: 0,
      DiaPrincipal: new Date(),
      DiaFiltro: getReferenciaYear() === null ? new Date() : new Date(getReferenciaYear(), getReferenciaMonth(), '01'),
      optionsPie: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: this.props.descricao,
          },
        },
      },
      chartDataPie: {
        labels: [],
        datasets: [
          {
            label: '',
            data: []
          }
        ]
      },
      AgendaMes: []
      , filtro: 'mensal'
      , mark: []
      , AgendaFiltro: []
      , Mensagem: "Carregando lancamentos"
    };

  }

  handleLogout = () => {

  }

  nextDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = (this.state.filtro === 'mensal' ? addMonths(this.state.DiaFiltro, parseInt(pDay)) : this.state.filtro === 'semanal' ? addWeeks(addDays(this.state.DiaPrincipal, 3), parseInt(pDay)) : addDays(this.state.DiaFiltro, parseInt(pDay)))
    if (pDay !== null) {
      this.setState({
        DiaPrincipal: newDay
      })
    }

    this.onClickDay(newDay);
  }
  handleLancamentoClick = (index) => {
    window.location = "/editar-lancamento/" + index;
  }
  handleSaldoClick = (index) => {
    window.location = "/editar-saldo/" + index;
  }
  handleNovoLancamento = () => {
    window.location = APP_URL() + "lancamento/novo";
  }
  handlecategoriaRelatorioClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/categoria/"+index+"/relatorio" ;
  }
  handleImportarReferenciaClick = e => {

    let textoBotaoUm = "Importar"
    let textoBotaoDois = "Cancelar"
    let pDiaFiltroCurrent = this.state.DiaFiltro;
    let hoje = new Date(pDiaFiltroCurrent === undefined ? this.state.DiaFiltro : pDiaFiltroCurrent);

    const handleBotaoUm = () => {

      lancamentoDataService.importarReferenciaAnterior(hoje.getFullYear(), hoje.getMonth() + 1)
        .then(response => {
          let pDiaFiltroCurrent = this.state.DiaFiltro;
          this.getlancamentos(pDiaFiltroCurrent);
          this.getsaldos(pDiaFiltroCurrent);
          this.getcategoriapie(pDiaFiltroCurrent);
          showMessageInfo(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          toast.error(message);
        });

    }
    const handleBotaoDois = () => { }
    showMessageConfirm("Você tem certeza que deseja proceder com a importação?",
      "Essa operação não pode ser revertida",
      "",
      {

        position: toast.POSITION.TOP_CENTER,
        containerId: '*',
        // theme: "dark",
        autoClose: false,
      },
      textoBotaoUm,
      handleBotaoUm,
      textoBotaoDois,
      handleBotaoDois
    );
  }
  handleLancamentoDeleteClick = e => {

    let textoBotaoUm = "Apagar"
    let textoBotaoDois = "Cancelar"
    const handleBotaoUm = () => {

      lancamentoDataService.delete(e)
        .then(response => {
          let pDiaFiltroCurrent = this.state.DiaFiltro;
          this.getlancamentos(pDiaFiltroCurrent);
          this.getsaldos(pDiaFiltroCurrent);
          this.getcategoriapie(pDiaFiltroCurrent);
          showMessageInfo(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          toast.error(message);
        });

    }
    const handleBotaoDois = () => { }
    showMessageConfirm("Você tem certeza que deseja excluir esse lançamento?",
      "Essa operação não pode ser revertida",
      "Será apagada para sempre",
      {

        position: toast.POSITION.TOP_CENTER,
        containerId: '*',
        // theme: "dark",
        autoClose: false,
      },
      textoBotaoUm,
      handleBotaoUm,
      textoBotaoDois,
      handleBotaoDois
    );
  }
  handleLancamentoReceberClick = e => {

    let textoBotaoUm = "Confirmar"
    let textoBotaoDois = "Cancelar"
    const handleBotaoUm = () => {

      lancamentoDataService.receberpagamento(e)
        .then(response => {
          let pDiaFiltroCurrent = this.state.DiaFiltro;
          this.getlancamentos(pDiaFiltroCurrent);
          this.getsaldos(pDiaFiltroCurrent);
          this.getcategoriapie(pDiaFiltroCurrent);
          showMessageInfo(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          toast.error(message);
        });

    }
    const handleBotaoDois = () => { }
    showMessageConfirm("Você tem certeza que deseja confirmar esse pagamento?",
      "Será usada a data de hoje e o valor total do lançamento",
      "",
      {

        position: toast.POSITION.TOP_CENTER,
        containerId: '*',
        // theme: "dark",
        autoClose: false,
      },
      textoBotaoUm,
      handleBotaoUm,
      textoBotaoDois,
      handleBotaoDois,
      'btn-success',
      'btn-light'
    );
  }
  clickDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = new Date(pDay);

    if ((pDay !== null)) {
      this.filtraAgenda(newDay.getDate(), newDay.getMonth(), newDay.getFullYear());
      if (this.state.filtro !== 'semanal') {
        this.setState({
          DiaPrincipal: newDay
        })
      }
    }
  }

  onClickDay(event) {
    this.filtraAgenda('01', event.getMonth(), event.getFullYear());
    localStorage.setItem('Referencia-month', event.getMonth());
    localStorage.setItem('Referencia-year', event.getFullYear());
  }

  filtraAgenda(day, month, year) {
    let pDiaFiltro = new Date(year, month, day);
    let pDiaFiltroCurrent = this.state.DiaFiltro;

    this.setState({
      AgendaFiltro: this.state.AgendaMes.filter(
        filtro => new Date(filtro.DataAgendamento).getDate() === pDiaFiltro.getDate()
          && new Date(filtro.DataAgendamento).getMonth() === pDiaFiltro.getMonth()
          && new Date(filtro.DataAgendamento).getFullYear() === pDiaFiltro.getFullYear()),
      DiaFiltro: pDiaFiltro
    });

    if (this.state.filtro !== 'semanal') {
      this.setState({
        DiaPrincipal: pDiaFiltro
      })
    }
    if (pDiaFiltroCurrent.getMonth() !== pDiaFiltro.getMonth() || pDiaFiltroCurrent.getFullYear() !== pDiaFiltro.getFullYear()) {

      this.setState({
        AgendaMes: [],
        mark: []

      })
      this.getlancamentos(pDiaFiltro);
      this.getsaldos(pDiaFiltro);
      this.getcategoriapie(pDiaFiltro);
    }
  }

  setFiltro = filter => {
    this.setState({ filtro: filter === 0 ? 'mensal' : (filter === 1 ? 'semanal' : 'diario') })

  }
  componentDidMount() {
    this.getlancamentos();
    this.getsaldos();
    this.getcategoriapie();
  }
  getcategoriapie(pDiaFiltro) {
    
    let pDiaFiltroCurrent = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);
    
    let hoje = new Date(pDiaFiltroCurrent === undefined ? this.state.DiaFiltro : pDiaFiltroCurrent);
    saldoDataService.getSaldoProjecaoReferencia(hoje.getFullYear(), (hoje.getMonth() + 1))

      .then(res => res.data)
      .then((jsonarray) => {

        var labels = jsonarray.map(function (e) {
          return e.referenciaDate.substring(0, 7);
        });


 
      var dataSaldo = jsonarray.map(function (e) {return e.Saldo;});
      var dataSaldoPendente = jsonarray.map(function (e) {return e.SaldoPendente;});
      var dataTotalEntrada = jsonarray.map(function (e) {return e.TotalEntrada;});
      var dataAreceber = jsonarray.map(function (e) {return e.Areceber;});
      var dataRecebido = jsonarray.map(function (e) {return e.Recebido;});
      var dataTotalSaida = jsonarray.map(function (e) {return e.TotalSaida;});
      var dataApagar = jsonarray.map(function (e) {return e.Apagar;});
      var dataPago = jsonarray.map(function (e) {return e.Pago;});         
      var dataTotalBalanco = jsonarray.map(function (e) {return e.TotalEntrada-  e.TotalSaida;});
        console.log(jsonarray);

        this.setState({
          optionsPie: {
            responsive: true,    
            plugins: {
              legend: {
                position: 'top',
                display: false
              },
              title: {
                display: true,
                text: 'Projeção'//jsonarray[0].descricao,
              },
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
              },
              // y1: {
              //   type: 'linear',
              //   display: false,
              //   position: 'right',
              //   grid: {
              //     drawOnChartArea: false,
              //   },
              // },
            },
          },
          chartDataPie: {
            labels: labels,
            datasets: [
              {
                label: 'Total Entradas',
                data: dataTotalEntrada,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderColor: 'rgb(53, 162, 235)',
                yAxisID: 'y',
              },
              {
                label: 'Valor à receber',
                data: dataAreceber,
                backgroundColor: 'rgba(53, 162, 235, 0.2)',
                borderColor: 'rgb(53, 162, 235, 0.2)',
                yAxisID: 'y',
              },
              {
                label: 'Valor recebido',
                data: dataRecebido,
                backgroundColor: 'rgba(53, 162, 235, 0.2)',
                borderColor: 'rgb(53, 162, 235, 0.2)',
                yAxisID: 'y',
              },
              {
                label: 'Total Saidas',
                data: dataTotalSaida,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',     
                borderColor: 'rgb(255, 99, 132)',
                yAxisID: 'y',        
              },
              {
                label: 'Total à pagar',
                data: dataApagar,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',   
                borderColor: 'rgb(255, 99, 132, 0.2)',
                yAxisID: 'y',                      
              },
              {
                label: 'Total pago',
                data: dataPago,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',     
                borderColor: 'rgb(255, 99, 132, 0.2)',
                yAxisID: 'y',          
              },
              {
                label: 'Saldo',
                data: dataSaldo,
                backgroundColor: 'rgba(99, 255 ,111, 0.5)',
                borderColor: 'rgb(99, 255 ,111, 0.5)',
                yAxisID: 'y',        
              },
              {
                label: 'Saldo Pendente',
                data: dataSaldoPendente,
                backgroundColor: 'rgba(99, 255 ,111, 0.2)',
                borderColor: 'rgb(99, 255 ,111, 0.2)',
                yAxisID: 'y',        
              },
              {
                label: 'Balanço',
                // type: 'line',
                data: dataTotalBalanco,
                backgroundColor: 'rgba(99, 255 ,111, 0.2)',
                // borderColor: 'rgb(99, 255 ,111, 0.2)',
                // yAxisID: 'y',        
              }
            ]
          }
        })
      })

      .catch(e => {
        console.log(e);
      });
  }

  calcularTotais() {
  
    var _totalentrada  = 0;    
    var _totalareceber = 0;
    var _totalrecebido = 0;
    var _totalsaida    = 0;
    var _totalapagar   = 0;
    var _totalpago     = 0;
    var _totalpendente = 0;

    this.state.lancamentos.forEach(element => {
      _totalpendente += element.datapagamento === null ? parseFloat(element.valor) : 0
      _totalentrada  += element.tipolancamento === 'Entrada'? parseFloat(element.valor) : 0 
      _totalareceber += element.tipolancamento === 'Entrada' && element.datapagamento === null ? parseFloat(element.valor) : 0
      _totalrecebido += element.tipolancamento === 'Entrada' && element.datapagamento !== null ? parseFloat(element.valorpago) : 0
      _totalsaida    += element.tipolancamento === 'Saída'? parseFloat(element.valor) : 0
      _totalapagar   += element.tipolancamento === 'Saída' && element.datapagamento === null ? parseFloat(element.valor) : 0
      _totalpago     += element.tipolancamento === 'Saída' && element.datapagamento !== null ? parseFloat(element.valorpago) : 0
    });
    this.setState({
      totalentrada: _totalentrada,
      totalareceber:  _totalareceber, 
      totalrecebido:  _totalrecebido,
      totalsaida:  _totalsaida,
      totalapagar:  _totalapagar,
      totalpago :  _totalpago ,
    })
  }

  calcularSaldoTotal() {
    var _total = 0.00;

    this.state.saldos.forEach(element => {
      _total += parseFloat(element.saldo)

    });
    this.setState({ saldototal: _total })
  }

  getlancamentos(pDiaFiltro) {
    let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);
    lancamentoDataService.getAllref(hoje.getFullYear(), (hoje.getMonth() + 1))
      .then(response => {
        this.setState({
          lancamentos: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
        this.calcularTotais();
      })
      .catch(e => {
     
      });
  }

  getsaldos(pDiaFiltro) {
    let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);
    saldoDataService.getAllref(hoje.getFullYear(), (hoje.getMonth() + 1))

      .then(response => {
        this.setState({
          saldos: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
        this.calcularSaldoTotal();
      })
      .catch(e => {
   
      });
  }


  render() {


    return (

      <div className="page-wrapper" >

        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Lancamentos" fluid={false}></Header>
        <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
        <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />        
        <section className="course-one course-one__teacher-details home-one">
          <Container fluid={false} className=" mb-3 calendario b-0">
            <Row className="card-body  m-0 p-0 card-border-width-0 card-border-radius-12">
              <Col xs={2} className="pt-3 p-0 ">
                <button className="btn btn-left pull-left p-0 m-0" to="#" onClick={this.nextDay} ><b><i className="material-icons p-2" data-key={-1}>chevron_left</i></b></button>
              </Col>
              <Col xs={8} className="text-center pt-2 pb-2 mt-1">
                <h5 className="agenda-data">
                  {
                    capitalizeFirstLetter
                      (
                        this.state.filtro === 'mensal'
                          ?

                          format(new Date(this.state.DiaFiltro), 'MMMM yyyy', { locale: ptBR })
                          :
                          this.state.filtro === 'semanal'
                            ?
                            (format(addDays(this.state.DiaPrincipal, -3), "dd") + " a " + format(addDays(this.state.DiaPrincipal, +3), "dd") + " de " + format(this.state.DiaPrincipal, "MMMM yyyy", { locale: ptBR }))
                            :
                            ((format(this.state.DiaFiltro, 'yyyyMMdd') === format(new Date(), 'yyyyMMdd')) ? 'Hoje' : format(this.state.DiaFiltro, "dd/MM/yyyy"))

                      )}</h5>
                <h6 className="agenda-data-revisoes">
                  {
                    this.state.filtro === 'mensal'
                      ? this.state.lancamentos.length
                      : (
                        this.state.filtro === 'semanal'
                          ? this.state.AgendaMes.filter(filtro => new Date(filtro.DataAgendamento).getUTCFullYear() === this.state.DiaPrincipal.getUTCFullYear()
                            && new Date(filtro.DataAgendamento).getMonth() === this.state.DiaPrincipal.getMonth()
                            && (
                              new Date(filtro.DataAgendamento).getDate() >= addDays(this.state.DiaPrincipal, -3).getDate()
                              && new Date(filtro.DataAgendamento).getDate() <= addDays(this.state.DiaPrincipal, +3).getDate()
                            )
                          ).length
                          : this.state.lancamentos.length
                      )
                  }  lançamentos</h6>
              </Col>
              <Col xs={2} className="text-right p-0 pt-3 " >
                <button className="btn btn-right pull-right p-0 m-0 " to="#" onClick={this.nextDay} ><b><i className="material-icons  p-2" data-key={+1}>chevron_right</i></b></button>
              </Col>
            </Row>
          </Container>         
          <Container fluid={false}>
            <Row className={""}>
              <Col className={"py-2"}>
                <Link to="/editar-lancamento" className="btn pull-right btn-info botao-acao mb-4" >+</Link>
                <br></br>
                {this.state.lancamentos.length === 0 ? <><Link to="#" onClick={() => this.handleImportarReferenciaClick()} className=" pull-right  mb-4 " >Importar lançamentos referência anterior.</Link></> : <></>}
              </Col>
            </Row>
            <Row >
              <Col className='p-0 m-0'>
                <Col className=''>
                  {                  
                    (this.state.lancamentos.filter(f => f.tipolancamento === 'Entrada').length > 0)
                    ?
                    <>
                      <h6>Entradas</h6>
                      <Table striped bordered hover size="sm" className='small' >
                        <thead>
                          <tr>
                            <th colSpan={4}></th>

                            <th>Categoria</th>
                            {/* <th>Descrição</th> */}
                            <th>Vencimento</th>
                            <th className='d-none d-xs-none d-sm-none d-md-table-cell   d-lg-table-cell   d-xl-table-cell  '>Pagamento</th>
                            <th>Valor</th>
                            <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pendente</th>
                            <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pago</th>
                            {/* <th>Pago</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.lancamentos.filter(f => f.tipolancamento === 'Entrada').map(data =>

                              <tr key={data.id}>
                                <td><Link to="#" onClick={() => this.handleLancamentoClick(data.id)} className={'small pull-left'}><small><i className="material-icons p-0  medium" data-key={-1}>edit</i></small></Link></td>
                                <td><Link to="#" onClick={() => this.handleLancamentoDeleteClick(data.id)} className={'small pull-left'}><small><i className="material-icons p-0  medium" data-key={-1}>delete</i></small></Link></td>
                                <td>
                                  {

                                    data.datapagamento === null
                                      ?
                                      // <>Não Pago</>
                                      <>                                         
                                        <Link to="#" onClick={() => this.handleLancamentoReceberClick(data.id)} className={('small pull-left ' + (isBefore(new Date(data.datavencimento), new Date()) === true ? 'text-danger' : 'text-info'))}><small><i className="material-icons p-0  medium" data-key={-1}>  payments</i></small></Link>
                                      </>
                                      :
                                      <>
                                        {
                                          isBefore(new Date(data.datapagamento), new Date()) === true
                                            ?
                                            <><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></>
                                            :
                                            <small className={'small pull-left'}><i className="material-icons p-0  medium" data-key={-1}>browse_gallery</i></small>
                                        }
                                      </>

                                  }
                                </td>
                                <td><span className={"text-right d-block text-info"} ><Link to="#"  onClick={() => this.handlecategoriaRelatorioClick(data.categoria_id)}><small><i className="material-icons p-0  medium" data-key={-1}>expand_more</i></small></Link></span></td>
                                <td>{data.descricao}</td>
                                {/* <td>{</td> */}
                                <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                                <td className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td>
                                <td><NumberFormat className="text-right d-block  " value={parseFloat(data.valor)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                                <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td>
                                {/* <td><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></td> */}

                              </tr>

                            )
                          }
                        </tbody>
                        <tfoot>
                          <tr className=' d-none d-xs-none d-sm-none d-md-table-row d-lg-table-row d-xl-table-row'>
                            <th scope="row" colSpan={6}>Totalizador</th>

                            <th><NumberFormat className="text-right d-block" value={this.state.totalentrada} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                            <th><NumberFormat className="text-right d-block" value={this.state.totalareceber} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                            <th><NumberFormat className="text-right d-block" value={this.state.totalrecebido} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                          </tr>

                          <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                            <th scope="row" colSpan={5}>Total</th>
                            <th colSpan={2} className="text-right" ><NumberFormat className="text-right d-block" value={this.state.total} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                          </tr>
                          <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                            <th scope="row" colSpan={5}>Total pendente</th>

                            <th colSpan={2}><NumberFormat className="text-right d-block" value={this.state.totalpendente} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>

                          </tr>
                          <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                            <th scope="row" colSpan={5}>Total Pago</th>

                            <th colSpan={2}><NumberFormat className="text-right d-block" value={this.state.totalpago} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                          </tr>
                        </tfoot> 
                      </Table>
                    </>
                    :
                    <></>
                  }
                </Col>
                <Col className=''>
                <h6>Saídas</h6>
                  <Table striped bordered hover size="sm" className='small' >
                    <thead>
                      <tr>
                        <th colSpan={4}></th>

                        <th>Categoria</th>
                        {/* <th>Descrição</th> */}
                        <th>Vencimento</th>
                        <th className='d-none d-xs-none d-sm-none d-md-table-cell   d-lg-table-cell   d-xl-table-cell  '>Pagamento</th>
                        <th>Valor</th>
                        <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pendente</th>
                        <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pago</th>
                        {/* <th>Pago</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.lancamentos.filter(f => f.tipolancamento === 'Saída').map(data =>

                          <tr key={data.id}>
                            <td><Link to="#" onClick={() => this.handleLancamentoClick(data.id)} className={'small pull-left'}><small><i className="material-icons p-0  medium" data-key={-1}>edit</i></small></Link></td>
                            <td><Link to="#" onClick={() => this.handleLancamentoDeleteClick(data.id)} className={'small pull-left'}><small><i className="material-icons p-0  medium" data-key={-1}>delete</i></small></Link></td>
                            <td>
                              {

                                data.datapagamento === null
                                  ?
                                  // <>Não Pago</>
                                  <>
                                    <Link to="#" onClick={() => this.handleLancamentoReceberClick(data.id)} className={('small pull-left ' + (isBefore(new Date(data.datavencimento), new Date()) === true ? 'text-danger' : 'text-info'))}><small><i className="material-icons p-0  medium" data-key={-1}>  payments</i></small></Link>
                                  </>
                                  :
                                  <>
                                    {
                                      isBefore(new Date(data.datapagamento), new Date()) === true
                                        ?
                                        <><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></>
                                        :
                                        <small className={'small pull-left'}><i className="material-icons p-0  medium" data-key={-1}>browse_gallery</i></small>
                                    }
                                  </>

                              }
                            </td>
                            <td><span className={"text-right d-block text-info"} ><Link to="#"  onClick={() => this.handlecategoriaRelatorioClick(data.categoria_id)}><small><i className="material-icons p-0  medium" data-key={-1}>expand_more</i></small></Link></span></td>
                            <td>{data.descricao}</td>
                            {/* <td>{</td> */}
                            <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                            <td className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td>
                            <td><NumberFormat className="text-right d-block  " value={parseFloat(data.valor)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                            <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                            <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td>
                            {/* <td><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></td> */}

                          </tr>

                        )
                      }
                    </tbody>
                    <tfoot>
                      <tr className=' d-none d-xs-none d-sm-none d-md-table-row d-lg-table-row d-xl-table-row'>
                        <th scope="row" colSpan={6}>Totalizador</th>

                        <th><NumberFormat className="text-right d-block" value={this.state.totalsaida} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalapagar} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalpago} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                      </tr>

                      <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                        <th scope="row" colSpan={5}>Total</th>
                        <th colSpan={2} className="text-right" ><NumberFormat className="text-right d-block" value={this.state.total} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                      </tr>
                      <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                        <th scope="row" colSpan={5}>Total pendente</th>

                        <th colSpan={2}><NumberFormat className="text-right d-block" value={this.state.totalpendente} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>

                      </tr>
                      <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                        <th scope="row" colSpan={5}>Total Pago</th>

                        <th colSpan={2}><NumberFormat className="text-right d-block" value={this.state.totalpago} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                      </tr>
                    </tfoot>
                  </Table>
                  {/* </div> */}
                </Col>
                <Col className=''>
                  {
                (this.state.lancamentos.filter(f => f.tipolancamento === 'Transferência').length > 0)
                    ?
                    <>
                    <h6>Transferencias</h6>
                    <Table striped bordered hover size="sm" className='small' >
                      <thead>
                        <tr>
                          <th colSpan={4}></th>

                          <th>Categoria</th>
                          {/* <th>Descrição</th> */}
                          <th>Vencimento</th>
                          <th className='d-none d-xs-none d-sm-none d-md-table-cell   d-lg-table-cell   d-xl-table-cell  '>Pagamento</th>
                          <th>Valor</th>
                          <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pendente</th>
                          <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pago</th>
                          {/* <th>Pago</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.lancamentos.filter(f => f.tipolancamento === 'Transferência').map(data =>

                            <tr key={data.id}>
                              <td>
                                <Link to="#" onClick={() => this.handleLancamentoClick(data.id)} className={'small pull-left'}><small><i className="material-icons p-0  medium" data-key={-1}>edit</i></small></Link>
                              </td>
                              <td>
                                <Link to="#" onClick={() => this.handleLancamentoDeleteClick(data.id)} className={'small pull-left'}><small><i className="material-icons p-0  medium" data-key={-1}>delete</i></small></Link>
                              </td>
                              
                              <td>
                                {

                                  data.datapagamento === null
                                    ?
                                    // <>Não Pago</>
                                    <>
                                      <Link to="#" onClick={() => this.handleLancamentoReceberClick(data.id)} className={('small pull-left ' + (isBefore(new Date(data.datavencimento), new Date()) === true ? 'text-danger' : 'text-info'))}><small><i className="material-icons p-0  medium" data-key={-1}>  payments</i></small></Link>
                                    </>
                                    :
                                    <>
                                      {
                                        isBefore(new Date(data.datapagamento), new Date()) === true
                                          ?
                                          <><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></>
                                          :
                                          <small className={'small pull-left'}><i className="material-icons p-0  medium" data-key={-1}>browse_gallery</i></small>
                                      }
                                    </>

                                }
                              </td>
                              <td><span className={"text-right d-block text-info"} ><Link to="#"  onClick={() => this.handlecategoriaRelatorioClick(data.categoria_id)}><small><i className="material-icons p-0  medium" data-key={-1}>expand_more</i></small></Link></span></td>
                              <td>{data.descricao}</td>
                              {/* <td>{</td> */}
                              <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                              <td className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td>
                              <td><NumberFormat className="text-right d-block  " value={parseFloat(data.valor)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                              <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                              <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td>
                              {/* <td><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></td> */}

                            </tr>

                          )
                        }
                      </tbody>
                      {/* <tfoot>
                        <tr className=' d-none d-xs-none d-sm-none d-md-table-row d-lg-table-row d-xl-table-row'>
                          <th scope="row" colSpan={6}>Totalizador</th>

                          <th> <NumberFormat className="text-right d-block" value={this.state.total} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                          <th><NumberFormat className="text-right d-block" value={this.state.totalpendente} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                          <th><NumberFormat className="text-right d-block" value={this.state.totalpago} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                        </tr>

                        <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                          <th scope="row" colSpan={5}>Total</th>
                          <th colSpan={2} className="text-right" ><NumberFormat className="text-right d-block" value={this.state.total} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                        </tr>
                        <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                          <th scope="row" colSpan={5}>Total pendente</th>

                          <th colSpan={2}><NumberFormat className="text-right d-block" value={this.state.totalpendente} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>

                        </tr>
                        <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                          <th scope="row" colSpan={5}>Total Pago</th>

                          <th colSpan={2}><NumberFormat className="text-right d-block" value={this.state.totalpago} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                        </tr>
                      </tfoot> */}
                    </Table>
                  </>
                    :
                    <></>
                  }
                  {/* </div> */}
                </Col>
                <Col className=''>
                {
                (this.state.lancamentos.filter(f => f.tipolancamento === 'Outros').length > 0)
                    ?
                    <>
                <h6>Outros</h6>
                  <Table striped bordered hover size="sm" className='small' >
                    <thead>
                      <tr>
                        <th colSpan={3}></th>

                        <th>Categoria</th>
                        {/* <th>Descrição</th> */}
                        <th>Vencimento</th>
                        <th className='d-none d-xs-none d-sm-none d-md-table-cell   d-lg-table-cell   d-xl-table-cell  '>Pagamento</th>
                        <th>Valor</th>
                        <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pendente</th>
                        <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pago</th>
                        {/* <th>Pago</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.lancamentos.filter(f => f.tipolancamento === 'Outros').map(data =>

                          <tr key={data.id}>
                            <td>
                              <Link to="#" onClick={() => this.handleLancamentoClick(data.id)} className={'small pull-left'}><small><i className="material-icons p-0  medium" data-key={-1}>edit</i></small></Link>
                            </td>
                            <td>
                              <Link to="#" onClick={() => this.handleLancamentoDeleteClick(data.id)} className={'small pull-left'}><small><i className="material-icons p-0  medium" data-key={-1}>delete</i></small></Link>
                            </td>
                            <td>
                              {

                                data.datapagamento === null
                                  ?
                                  // <>Não Pago</>
                                  <>
                                    <Link to="#" onClick={() => this.handleLancamentoReceberClick(data.id)} className={('small pull-left ' + (isBefore(new Date(data.datavencimento), new Date()) === true ? 'text-danger' : 'text-info'))}><small><i className="material-icons p-0  medium" data-key={-1}>  payments</i></small></Link>
                                  </>
                                  :
                                  <>
                                    {
                                      isBefore(new Date(data.datapagamento), new Date()) === true
                                        ?
                                        <><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></>
                                        :
                                        <small className={'small pull-left'}><i className="material-icons p-0  medium" data-key={-1}>browse_gallery</i></small>
                                    }
                                  </>

                              }
                            </td>
                            <td>{data.descricao}</td>
                            {/* <td>{</td> */}
                            <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                            <td className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td>
                            <td><NumberFormat className="text-right d-block  " value={parseFloat(data.valor)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                            <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                            <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td>
                            {/* <td><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></td> */}

                          </tr>

                        )
                      }
                    </tbody>
                    {/* <tfoot>
                      <tr className=' d-none d-xs-none d-sm-none d-md-table-row d-lg-table-row d-xl-table-row'>
                        <th scope="row" colSpan={6}>Totalizador</th>

                        <th> <NumberFormat className="text-right d-block" value={this.state.total} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalpendente} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalpago} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                      </tr>

                      <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                        <th scope="row" colSpan={5}>Total</th>
                        <th colSpan={2} className="text-right" ><NumberFormat className="text-right d-block" value={this.state.total} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                      </tr>
                      <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                        <th scope="row" colSpan={5}>Total pendente</th>

                        <th colSpan={2}><NumberFormat className="text-right d-block" value={this.state.totalpendente} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>

                      </tr>
                      <tr className='d-table-row d-xs-table-row d-sm-table-row d-md-none d-lg-none d-xl-none'>
                        <th scope="row" colSpan={5}>Total Pago</th>

                        <th colSpan={2}><NumberFormat className="text-right d-block" value={this.state.totalpago} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                      </tr>
                    </tfoot> */}
                  </Table>
                  </>
                    :
                    <></>
                  }
                  {/* </div> */}
                </Col>
               
              </Col>
              <Col className='' md={4} lg={4} xl={4}  >
                <h6>Saldos</h6>  
                <Table striped bordered hover size="sm" className='small'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Descrição</th>
                      <th>Saldo</th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.saldos.map(data =>

                        <tr key={data.id}>
                          <td><Link to="#" onClick={() => this.handleSaldoClick(data.id)} className={'small'}><small><i className="material-icons p-0  medium" data-key={-1}>edit</i></small></Link></td>
                          <td>{data.conta[0].descricao}</td>
                          <td><NumberFormat className="text-right d-block" value={parseFloat(data.saldo)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                        </tr>

                      )
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <th scope="row" colSpan={2}>Total contas</th>
                      {/* <td></td> */}
                      <td><NumberFormat className="text-right d-block" value={this.state.saldototal} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                    </tr>
                    <tr>
                      <th scope="row" colSpan={2}>Saldo </th>

                      <td><NumberFormat className={"text-right d-block " + (this.state.saldototal - this.state.totalapagar < 0 ? "text-danger" : "text-info")} value={this.state.saldototal - this.state.totalapagar} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                    </tr>
                  </tfoot>
                </Table>
                <Table striped bordered hover size="sm" className='small' >
                  <tfoot>
                      <tr className=' '>
                        <th scope="row" colSpan={6}>Total de entradas</th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalentrada} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>                        
                      </tr>
                      <tr className=' '>
                        <th scope="row" colSpan={6}>Total à receber</th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalareceber} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>                        
                      </tr>
                      <tr className=' '>
                        <th scope="row" colSpan={6}>Total recebido</th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalrecebido} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>                        
                      </tr>
                    </tfoot>
                  </Table>
                  <Table striped bordered hover size="sm" className='small' >
                  <tfoot>
                      <tr className=' '>
                        <th scope="row" colSpan={6}>Total de saídas </th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalsaida} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>                        
                      </tr>
                      <tr className=' '>
                        <th scope="row" colSpan={6}>Total à pagar</th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalapagar} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>                        
                      </tr>
                      <tr className=' '>
                        <th scope="row" colSpan={6}>Total pago</th>
                        <th><NumberFormat className="text-right d-block" value={this.state.totalpago} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>                        
                      </tr>
                    </tfoot>
                  </Table>
                  <Table striped bordered hover size="sm" className='small' >
                    <tfoot>
                      <tr className=' '>
                        <th scope="row" colSpan={5}>Saldo em Contas</th>
                        <th colSpan={2} className="text-right" ><NumberFormat className="text-right d-block" value={this.state.saldototal} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>
                      </tr>
                      <tr className=' '>
                        <th scope="row" colSpan={5}>Total (Saldo - Total Pendente)</th>
                        <th colSpan={2}><NumberFormat className={"text-right d-block " + (this.state.saldototal - this.state.totalapagar < 0 ? "text-danger" : "text-info")} value={parseFloat(this.state.saldototal) - parseFloat(this.state.totalapagar)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>                        
                      </tr>   

                       <tr className=' '>
                        <th scope="row" colSpan={5}>Balanço (Entradas - Saídas)</th>
                        <th colSpan={2}><NumberFormat className={"text-right d-block " + (this.state.totalentrada - this.state.totalsaida < 0 ? "text-danger" : "text-info")} value={parseFloat(this.state.totalentrada) - parseFloat(this.state.totalsaida)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></th>                        
                      </tr>                   
                    </tfoot>
                  </Table>
                  <Bar  options={this.state.optionsPie}  data={this.state.chartDataPie} />                    
               </Col>
                  <Col className='' md={4} lg={4} xl={4}  >
                
                </Col>
            </Row>
          </Container>         
        </section>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default lancamentos;
