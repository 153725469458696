import http from "../utils/apiAvancada";

class LancamentoDataService {
  getAll() {
    return http.get("/lancamento/");
  }
  getAllContasAPagar() {
    return http.get(`/lancamento/APagar`);
  }
  getAllref(year, month) {
    return http.get(`/lancamento/${year}/${month}`);
  }
  receberpagamento(id) {
    return http.post(`/lancamento/receberpagamento/${id}`);
  }
  importarReferenciaAnterior(year, month) {
    return http.post(`/lancamento/importarReferenciaAnterior/${year}/${month}`);
  }
  get(id) {
    return http.get(`/lancamento/${id}`);
  }

  create(data) {
    return http.post("/lancamento", data);
  }

  update(id, data) {
    return http.put(`/lancamento/${id}`, data);
  }

  delete(id) {
    return http.delete(`/lancamento/${id}`);
  }

  deleteAll() {
    return http.delete(`/lancamento`);
  }

  findByTitle(title) {
    return http.get(`/lancamento?title=${title}`);
  }
}

export default new LancamentoDataService();