import http from "../utils/apiAvancada";

class UsuarioDataService {
  getAll() {
    return http.get("/usuarios");
  }

  getAbout() {
    return http.get(`/usuarios/about`);
  }

  get(id) {
    return http.get(`/usuarios/${id}`);
  }

  create(data) {
    return http.post("/usuario", data);
  }

  update(id, data) {
    return http.put(`/usuarios/${id}`, data);
  }

  delete(id) {
    return http.delete(`/usuarios/${id}`);
  }

  deleteAll() {
    return http.delete(`/usuario`);
  }

  findByTitle(title) {
    return http.get(`/usuario?title=${title}`);
  }
}

export default new UsuarioDataService();