import React, { Component, useState } from 'react';
import lancamentoDataService from "../services/lancamento.service";
import saldoDataService from "../services/saldo.service";
import contaDataService from "../services/conta.service";
import categoriaDataService from "../services/categoria.service";
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';

// import 'react-toastify/dist/ReactToastify.css';
// import { useNavigate  } from 'react-router'
import { Redirect } from 'react-router';
// import { browserHistory } from './react-router'

// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, getReferenciaDate, showMessageError, showMessageSuccess } from '../utils';
import TextInput from './Common/TextInput';

// const columns = [
//   { name: '#', selector: 'id', sortable: true, },
//   // { name: 'descricao', selector: 'descricao', sortable: true,  cell: row => <>{row.user === null ? 'Usuário inválido' : `${row.user.nome} (${row.user.email})` } </>, },
//   // { name: 'Plano', selector: 'planoId', sortable: true, wrap: true, compact: true,  cell: row => <>{row.plano === null ? 'Sem plano ou com Código ' : row.plano.titulo} </>,},
//   { name: 'referencia', selector: 'referencia', sortable: true, right: true, },
//   { name: 'descricao', selector: 'descricao', sortable: true, right: true, },
//   { name: 'datavencimento', selector: 'datavencimento', sortable: true, compact: true, },
//   { name: 'datapagamento', selector: 'datapagamento', sortable: true, compact: true, },
//   { name: 'valor', selector: 'valor', sortable: true, compact: true, },
//   { name: 'valorpago', selector: 'valorpago', sortable: true, compact: true, },
//   { name: 'Valor Pendente', sortable: true, right: true,   cell: row => <>{row.valorpago === null || row.valorpago === 0 ? row.valor : "0.00" } </>},
//   // { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
// ]
const handleButtonClick = (state) => {
  window.location = "/editar-motorista?id=" + state.id;
};

// const [show, setShow] = useState(false);
class LancamentoEditar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: undefined
      , referencia: getReferenciaDate().toISOString().substring(0, 7)
      , descricao: undefined
      , observacao: undefined
      , datavencimento: undefined
      , valor: undefined
      , show: false
      , datapagamento: undefined
      , valorpago: undefined
      , contaOrigem_id: undefined
      , contaDestino_id: undefined
      , categoria_id: undefined
      , formapagamento_id: undefined
      , lstcategorias: [], lstcontas: []

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }
  handleDataChange = (event, editor) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleLogout = () => {

  }
  onSelect(event) {
    const selectedIndex = event.target.options.selectedIndex;
    // console.log(event.target.options.value)
    console.log('event.target.name', event.target.name)
    console.log('selectedIndex', selectedIndex)
    this.setState({ [event.target.name]: event.target.options[selectedIndex].getAttribute('data-key') });
  }
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.id !== undefined) {

      let data = JSON.stringify({
        "referencia": this.state.referencia
        , "descricao": this.state.descricao
        , "observacao": this.state.observacao
        , "datavencimento": this.state.datavencimento
        , "valor": this.state.valor
        , "datapagamento": this.state.datapagamento
        , "valorpago": this.state.valorpago
        , "contaOrigem_id": this.state.contaOrigem_id
        , "contaDestino_id": this.state.contaDestino_id
        , "categoria_id": this.state.categoria_id
        , "formapagamento_id": this.state.formapagamento_id
      });

      lancamentoDataService.update(this.state.id, data)
        .then(response => {
          this.props.history.push('/lancamentos');
          showMessageSuccess(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          showMessageError(message);
        });
    }
    else {

      let data = JSON.stringify({
        "id": this.state.id
        , "referencia": this.state.referencia
        , "descricao": this.state.descricao
        , "observacao": this.state.observacao
        , "datavencimento": this.state.datavencimento
        , "valor": this.state.valor
        , "datapagamento": this.state.datapagamento
        , "valorpago": this.state.valorpago
        , "contaOrigem_id": this.state.contaOrigem_id
        , "contaDestino_id": this.state.contaDestino_id
        , "categoria_id": this.state.categoria_id
        , "formapagamento_id": this.state.formapagamento_id
      });
      lancamentoDataService.create(data)
        .then(response => {
          this.props.history.push('/lancamentos');
          showMessageSuccess(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          showMessageError(message);
        });
    }
  };



  getCategorias() {

    categoriaDataService.getAll()
      .then(response => {
        this.setState({
          lstcategorias: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
        this.calcularSaldoTotal();
      })
      .catch(e => {
        //console.log(e);
      });
  }

  getContas() {

    contaDataService.getAll()
      .then(response => {
        this.setState({
          lstcontas: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
      })
      .catch(e => {
        //console.log(e);
      });
  }

  getlancamento(lancamento) {
    // let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);

    lancamentoDataService.get(lancamento)
      .then(response => {
        this.setState({
          id: response.data.id,
          referenciaStr: response.data.referencia,
          referencia: response.data.referencia.substring(0, 7),
          descricao: response.data.descricao,
          observacao: response.data.observacao,
          datavencimento: response.data.datavencimento.substring(0, 10),
          valor: response.data.valor,
          datapagamento: response.data.datapagamento !== null ? response.data.datapagamento.substring(0, 10) : response.data.datapagamento,
          valorpago: response.data.valorpago,
          contaOrigem_id: response.data.contaOrigem_id,
          contaDestino_id: response.data.contaDestino_id,
          categoria_id: response.data.categoria_id,
          formapagamento_id: response.data.formapagamento_id,
          Mensagem: 'Nenhum registro para exibir'
        });
        console.log.apply(response.data)
      })
      .catch(e => {
        //console.log(e);
      });
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let lancamento = query.has('id') ? query.get('id') : (this.props.match.params.id !== undefined ? this.props.match.params.id : '-');

    this.getlancamento(lancamento);
    this.getCategorias();
    this.getContas();
  }

  render() {

    if (this.state.redirect) {
      return <Redirect push to="/lancamentos" />;
    }

    return (

      <>
        <div className="page-wrapper" >
          <NavTop></NavTop>
          <NavMain></NavMain>

          <Header title={(this.state.id !== undefined ? 'Editando' : 'Inserindo') + " lancamento "} ></Header>
          {/* <section className="course-one course-one__teacher-details home-one"> */}
          <Container className='pb-5'>

            <form onSubmit={this.handleSubmit}>
              <Row>
                <Col sm={12} className="form-group">
                  <TextInput type="hidden" labelValue={"id"} id={"id"} name={"id"} defaultValue={this.state.id} value={this.state.id} className="form-control" />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="form-group">

                  {/* <>{getReferenciaDate()}</> */}

                  <TextInput required={"required"} type={"month"} onChange={this.handleDataChange} labelValue={"Referencia > " + this.state.referencia + " | " + this.state.referenciaStr} id={"referencia"} name={"referencia"} value={this.state.referencia} className="form-control" />
                </Col>
                <Col sm={6} className="form-group"></Col>
                <Col sm={4} className="form-group">
                  <label htmlFor={'categoria_id'} className={""}>{'Categoria'}-{this.state.categoria_id}</label>
                  <select required={"required"} name='categoria_id' id="categoria_id" className="form-control" onChange={this.onSelect} value={this.state.categoria_id} >
                    <option data-key={null} key={null} value={null}>{""} </option>
                    {
                      this.state.lstcategorias.map(e =>
                        <option data-key={e.id} key={e.id} value={e.id}>{e.descricao} </option>
                      )
                    }
                  </select>
                </Col>

                <Col sm={4} className="form-group">
                  <label htmlFor={'contaOrigem_id'} className={""}>{'Conta Origem'}-{this.state.contaOrigem_id}</label>
                  <select name='contaOrigem_id' id="contaOrigem_id" className="form-control" onChange={this.onSelect} value={this.state.contaOrigem_id} >
                    <option data-key={null} key={null} value={null}>{""} </option>
                    {
                      this.state.lstcontas.map(e =>
                        <option data-key={e.id} key={e.id} value={e.id}>{e.descricao} </option>
                      )
                    }
                  </select>
                </Col>
                <Col sm={4} className="form-group">
                  <label htmlFor={'contaDestino_id'} className={""}>{'Conta Destino'}-{this.state.contaDestino_id}</label>
                  <select name='contaDestino_id' id="contaDestino_id" className="form-control" onChange={this.onSelect} value={this.state.contaDestino_id} >
                    <option data-key={null} key={null} value={null}>{""} </option>
                    {
                      this.state.lstcontas.map(e =>
                        <option data-key={e.id} key={e.id} value={e.id}>{e.descricao} </option>
                      )
                    }
                  </select>
                </Col>

                <Col sm={12} className="form-group">

                  <TextInput required={"required"} onChange={this.handleDataChange} labelValue={"Descrição"} id={"descricao"} name={"descricao"} value={this.state.descricao} className="form-control" />
                </Col>


              </Row>
              <Row>
                <Col sm={4} className="form-group">
                  <TextInput required={"required"} type={"date"} onChange={this.handleDataChange} labelValue={"Data de vencimento"} id={"datavencimento"} name={"datavencimento"} value={this.state.datavencimento} className="form-control" />
                </Col>
                <Col sm={4} className="form-group">
                  <TextInput required={"required"} type={"number"} step="0.01" onChange={this.handleDataChange} labelValue={"Valor"} id={"valor"} name={"valor"} value={this.state.valor} className="form-control" />
                </Col>
              </Row>

              <Row>
                <Col sm={4} className="form-group">
                  <TextInput type={"date"} onChange={this.handleDataChange} labelValue={"Data de pagamento"} id={"datapagamento"} name={"datapagamento"} value={this.state.datapagamento} className="form-control" />
                </Col>
                <Col sm={4} className="form-group">
                  <TextInput type={"number"} step="0.01" onChange={this.handleDataChange} labelValue={"Valor pago"} id={"valorpago"} name={"valorpago"} value={this.state.valorpago} className="form-control" />
                </Col>
              </Row>

              <Row className={'d-none'}>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"contaOrigem_id"} id={"contaOrigem_id"} name={"contaOrigem_id"} value={this.state.contaOrigem_id} className="form-control" />
                </Col>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"contaDestino_id"} id={"contaDestino_id"} name={"contaDestino_id"} value={this.state.contaDestino_id} className="form-control" />
                </Col>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"categoria_id"} id={"categoria_id"} name={"categoria_id"} value={this.state.categoria_id} className="form-control" />
                </Col>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"formapagamento_id"} id={"formapagamento_id"} name={"formapagamento_id"} value={this.state.formapagamento_id} className="form-control" />
                </Col>

              </Row>
              <Row>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"Observação"} id={"observacao"} name={"observacao"} value={this.state.observacao} className="form-control" />
                </Col>
              </Row>
              <Row className="row">
                <Col xs={12} sm={12} md={12} className="form-group  text-right">

                  <button className="btn btn-primary mr-2" type="submit">Salvar lançamento</button>
                  <Link to="/lancamentos" className="btn btn-warning" type="button">Voltar</Link>

                </Col>
                <Col className="form-group col-md-4">
                  <div className="">

                  </div>
                </Col>
              </Row>
            </form>
          </Container>

          {/* </section> */}

          <FooterMenu></FooterMenu>
          <Footer></Footer>
        </div >
      </>
    );
  }
}
export default LancamentoEditar;
