import React, { Component } from 'react';
import lancamentoDataService from "../services/lancamento.service";
import saldoDataService from "../services/saldo.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, capitalizeFirstLetter, getReferenciaMonth, getReferenciaYear } from '../utils';


const handleButtonClick = (state) => {
  window.location = "/editar-lancamento";
};

class saldos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lancamentos: [],
      saldos: [],
      total: 0,
      totalpago: 0,
      totalpendente: 0,
      saldototal: 0,
      DiaPrincipal: new Date(),
      DiaFiltro: getReferenciaYear() === null ? new Date() : new Date(getReferenciaYear(), getReferenciaMonth(), '01'),

      AgendaMes: []
      , filtro: 'mensal'
      , mark: []
      , AgendaFiltro: []
      , Mensagem: "Carregando lancamentos"
    };

  }

  handleLogout = () => {

  }

  nextDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = (this.state.filtro === 'mensal' ? addMonths(this.state.DiaFiltro, parseInt(pDay)) : this.state.filtro === 'semanal' ? addWeeks(addDays(this.state.DiaPrincipal, 3), parseInt(pDay)) : addDays(this.state.DiaFiltro, parseInt(pDay)))
    if (pDay !== null) {
      this.setState({
        DiaPrincipal: newDay
      })
    }

    this.onClickDay(newDay);
  }
  handleLancamentoClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/editar-lancamento/" + index;
  }
  handleSaldoClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/editar-saldo/" + index;
  }
  handleNovoLancamento = () => {
    window.location = APP_URL() + "lancamento/novo";
  }
  clickDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = new Date(pDay);

    if ((pDay !== null)) {
      this.filtraAgenda(newDay.getDate(), newDay.getMonth(), newDay.getFullYear());
      if (this.state.filtro !== 'semanal') {
        this.setState({
          DiaPrincipal: newDay
        })
      }
    }
  }

  onClickDay(event) {
    this.filtraAgenda('01', event.getMonth(), event.getFullYear());
    // localStorage.setItem('Referencia-day', event.getDate());
    localStorage.setItem('Referencia-month', event.getMonth());
    localStorage.setItem('Referencia-year', event.getFullYear());
  }

  filtraAgenda(day, month, year) {
    let pDiaFiltro = new Date(year, month, day);
    let pDiaFiltroCurrent = this.state.DiaFiltro;

    this.setState({
      AgendaFiltro: this.state.AgendaMes.filter(
        filtro => new Date(filtro.DataAgendamento).getDate() === pDiaFiltro.getDate()
          && new Date(filtro.DataAgendamento).getMonth() === pDiaFiltro.getMonth()
          && new Date(filtro.DataAgendamento).getFullYear() === pDiaFiltro.getFullYear()),
      DiaFiltro: pDiaFiltro
    });

    if (this.state.filtro !== 'semanal') {
      this.setState({
        DiaPrincipal: pDiaFiltro
      })
    }
    if (pDiaFiltroCurrent.getMonth() !== pDiaFiltro.getMonth() || pDiaFiltroCurrent.getFullYear() !== pDiaFiltro.getFullYear()) {

      this.setState({
        AgendaMes: [],
        mark: []

      })

      this.getsaldos(pDiaFiltro);
    }
  }

  setFiltro = filter => {
    this.setState({ filtro: filter === 0 ? 'mensal' : (filter === 1 ? 'semanal' : 'diario') })

  }
  componentDidMount() {
    this.getsaldos();
  }
  calcularTotais() {
    var _total = 0.00;
    var _totalpago = 0;
    var _totalpendente = 0;
    this.state.lancamentos.forEach(element => {
      _total += parseFloat(element.valor)
      _totalpago += element.valorpago === null ? 0 : parseFloat(element.valorpago)
      _totalpendente += element.datapagamento === null ? parseFloat(element.valor) : 0
      // debugger
      if (element.datapagamento === null)
        console.log(element)
    });
    this.setState({ total: _total, totalpago: _totalpago, totalpendente: _totalpendente })
  }

  calcularSaldoTotal() {
    var _total = 0.00;

    this.state.saldos.forEach(element => {
      _total += parseFloat(element.saldo)

    });
    this.setState({ saldototal: _total })
  }



  getsaldos(pDiaFiltro) {
    let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);
    saldoDataService.getAllref(hoje.getFullYear(), (hoje.getMonth() + 1))

      .then(response => {
        this.setState({
          saldos: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
        this.calcularSaldoTotal();
      })
      .catch(e => {
        //console.log(e);
      });
  }


  render() {


    return (

      <div className="page-wrapper" >

        <NavTop></NavTop>
        <NavMain></NavMain>
        {/* <button onClick={notify}>Notify!</button> */}
        <Header title="saldos..." fluid={true}></Header>
        <section className="course-one course-one__teacher-details home-one">
          <Container fluid={false} className=" mb-3 calendario b-0">
            <Row className="card-body  m-0 p-0 card-border-width-0 card-border-radius-12">
              <Col xs={2} className="pt-3 ">
                <button className="btn btn-left pull-left p-0 m-0" to="#" onClick={this.nextDay} ><b><i className="material-icons p-2" data-key={-1}>chevron_left</i></b></button>
              </Col>
              <Col xs={8} className="text-center pt-2 pb-2 mt-1">
                <h5 className="agenda-data">
                  {
                    capitalizeFirstLetter
                      (
                        this.state.filtro === 'mensal'
                          ?

                          format(new Date(this.state.DiaFiltro), 'MMMM yyyy', { locale: ptBR })
                          :
                          this.state.filtro === 'semanal'
                            ?
                            (format(addDays(this.state.DiaPrincipal, -3), "dd") + " a " + format(addDays(this.state.DiaPrincipal, +3), "dd") + " de " + format(this.state.DiaPrincipal, "MMMM yyyy", { locale: ptBR }))
                            :
                            ((format(this.state.DiaFiltro, 'yyyyMMdd') === format(new Date(), 'yyyyMMdd')) ? 'Hoje' : format(this.state.DiaFiltro, "dd/MM/yyyy"))

                      )}</h5>
                <h6 className="agenda-data-revisoes">
                  {
                    this.state.filtro === 'mensal'
                      ? this.state.saldos/*.filter(filtro => new Date(filtro.DataAgendamento).getUTCFullYear() === this.state.DiaPrincipal.getUTCFullYear()
                                                       && new Date(filtro.DataAgendamento).getMonth() === this.state.DiaPrincipal.getMonth()
                                               )*/.length
                      : (
                        this.state.filtro === 'semanal'
                          ? this.state.AgendaMes.filter(filtro => new Date(filtro.DataAgendamento).getUTCFullYear() === this.state.DiaPrincipal.getUTCFullYear()
                            && new Date(filtro.DataAgendamento).getMonth() === this.state.DiaPrincipal.getMonth()
                            && (
                              new Date(filtro.DataAgendamento).getDate() >= addDays(this.state.DiaPrincipal, -3).getDate()
                              && new Date(filtro.DataAgendamento).getDate() <= addDays(this.state.DiaPrincipal, +3).getDate()
                            )
                          ).length
                          : this.state.saldos.length
                      )
                  }  saldos mensais das contas</h6>
              </Col>
              <Col xs={2} className="text-left pt-3 " >
                <button className="btn btn-right pull-right p-0 m-0 " to="#" onClick={this.nextDay} ><b><i className="material-icons  p-2" data-key={+1}>chevron_right</i></b></button>
              </Col>
            </Row>
          </Container>
          <Container fluid={false}>
            <Row className={""}>
              <Col className={"py-2 "}>
                <Link to="/editar-lancamento" className="btn pull-right btn-info botao-acao mb-4" >+</Link>
              </Col>
            </Row>
            <Row>
              <Col className=''>
                <Table striped bordered hover size="sm" className='small'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Descrição</th>
                      <th>Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.saldos.map(data =>
                        <tr key={data.id}>
                          <td><Link onClick={() => this.handleSaldoClick(data.id)}> <i className="material-icons p-2" data-key={-1}>mode_edit</i></Link></td>
                          <td>{data.conta[0].descricao}</td>
                          <td><NumberFormat className="text-right d-block" value={parseFloat(data.saldo)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                        </tr>
                      )
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <th scope="row" colSpan={2}>Total contas</th>
                      <td><NumberFormat className="text-right d-block" value={this.state.saldototal} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>
          </Container>
        </section>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default saldos;
