import React, { Component } from 'react';

import packageJson from '../../../package.json';
// import { Col, Row } from 'react-bootstrap';
// import { Link, NavLink } from 'react-router-dom';
import { Link, } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getEnv } from '../../utils';

class FooterMenu extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {

        console.log(packageJson.version)

    }

    render() {
        return (

            <footer className="site-footer">

                <div className="site-footer__bottom">
                    <div className="container-fluid">
                        <div className="col py-3 text-left site-footer__copy font-size-14 lh-14">
                            <Row>
                                <Col sm={12}>&copy; Copyright 2021 by - <Link to="#">Campos Brito | Meu Financeiro</Link></Col>
                                <Col sm={12}><small >Version - {packageJson.version} | Meu financeiro | {getEnv()}</small></Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </footer>


        );
    }
}
export default FooterMenu;