import React, { Component } from 'react';
import lancamentoDataService from "../services/lancamento.service";
import saldoDataService from "../services/saldo.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, capitalizeFirstLetter } from '../utils';

// const columns = [
//   { name: '#', selector: 'id', sortable: true, },
//   // { name: 'descricao', selector: 'descricao', sortable: true,  cell: row => <>{row.user === null ? 'Usuário inválido' : `${row.user.nome} (${row.user.email})` } </>, },
//   // { name: 'Plano', selector: 'planoId', sortable: true, wrap: true, compact: true,  cell: row => <>{row.plano === null ? 'Sem plano ou com Código ' : row.plano.titulo} </>,},
//   { name: 'referencia', selector: 'referencia', sortable: true, right: true, },
//   { name: 'descricao', selector: 'descricao', sortable: true, right: true, },
//   { name: 'datavencimento', selector: 'datavencimento', sortable: true, compact: true, },
//   { name: 'datapagamento', selector: 'datapagamento', sortable: true, compact: true, },
//   { name: 'valor', selector: 'valor', sortable: true, compact: true, },
//   { name: 'valorpago', selector: 'valorpago', sortable: true, compact: true, },
//   { name: 'Valor Pendente', sortable: true, right: true,   cell: row => <>{row.valorpago === null || row.valorpago === 0 ? row.valor : "0.00" } </>},
//   // { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
// ]
const handleButtonClick = (state) => {
    window.location = "/editar-motorista?id=" + state.id;
};

class Lancamento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lancamentos: [],
            saldos: [],
            total: 0,
            totalpago: 0,
            totalpendente: 0,
            saldototal: 0,
            DiaPrincipal: new Date(),
            DiaFiltro: new Date(),
            AgendaMes: []
            , filtro: 'mensal'
            , mark: []
            , AgendaFiltro: []
            , Mensagem: "Carregando lancamentos"
        };

    }

    handleLogout = () => {

    }

    nextDay = e => {
        e.preventDefault();
        let pDay = e.target.getAttribute('data-key');
        let newDay = (this.state.filtro === 'mensal' ? addMonths(this.state.DiaFiltro, parseInt(pDay)) : this.state.filtro === 'semanal' ? addWeeks(addDays(this.state.DiaPrincipal, 3), parseInt(pDay)) : addDays(this.state.DiaFiltro, parseInt(pDay)))
        if (pDay !== null) {
            this.setState({
                DiaPrincipal: newDay
            })
        }
        this.onClickDay(newDay);
    }
    handleLancamentoClick = (index) => {
        console.log(index);
        localStorage.setItem('IdEmpresa', index);
        window.location = APP_URL() + "lancamento/" + index;
    }

    handleNovoLancamento = () => {
        window.location = APP_URL() + "lancamento/novo";
    }
    clickDay = e => {
        e.preventDefault();
        let pDay = e.target.getAttribute('data-key');
        let newDay = new Date(pDay);

        if ((pDay !== null)) {
            this.filtraAgenda(newDay.getDate(), newDay.getMonth(), newDay.getFullYear());
            if (this.state.filtro !== 'semanal') {
                this.setState({
                    DiaPrincipal: newDay
                })
            }
        }
    }

    onClickDay(event) {
        this.filtraAgenda(event.getDate(), event.getMonth(), event.getFullYear());
    }

    filtraAgenda(day, month, year) {
        let pDiaFiltro = new Date(year, month, day);
        let pDiaFiltroCurrent = this.state.DiaFiltro;

        this.setState({
            AgendaFiltro: this.state.AgendaMes.filter(
                filtro => new Date(filtro.DataAgendamento).getDate() === pDiaFiltro.getDate()
                    && new Date(filtro.DataAgendamento).getMonth() === pDiaFiltro.getMonth()
                    && new Date(filtro.DataAgendamento).getFullYear() === pDiaFiltro.getFullYear()),
            DiaFiltro: pDiaFiltro
        });

        if (this.state.filtro !== 'semanal') {
            this.setState({
                DiaPrincipal: pDiaFiltro
            })
        }
        if (pDiaFiltroCurrent.getMonth() !== pDiaFiltro.getMonth() || pDiaFiltroCurrent.getFullYear() !== pDiaFiltro.getFullYear()) {

            this.setState({
                AgendaMes: [],
                mark: []

            })
            this.getlancamentos(pDiaFiltro);
            this.getsaldos(pDiaFiltro);
        }
    }

    setFiltro = filter => {
        this.setState({ filtro: filter === 0 ? 'mensal' : (filter === 1 ? 'semanal' : 'diario') })

    }
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        let lancamento = query.has('id') ? query.get('id') : (this.props.match.params.id !== undefined ? this.props.match.params.id : '-');

        this.getlancamento(lancamento);
        // this.getsaldos();
    }




    getlancamento(lancamento) {
        // let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);
        lancamentoDataService.get(lancamento)
            .then(response => {
                this.setState({
                    lancamentos: [response.data],
                    Mensagem: 'Nenhum registro para exibir'
                });
                this.calcularTotais();
            })
            .catch(e => {
                //console.log(e);
            });
    }



    render() {


        return (

            <div className="page-wrapper" >
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="lancamentos" ></Header>
                <section className="course-one course-one__teacher-details home-one">

                    <Container fluid={true}>
                        {/* {this.state.lancamentos.length > 0
              ?
              <> */}
                        <Container fluid={true}>
                            <Row className={"pull-right"}>

                                <Col>


                                    <Table striped bordered hover size="sm" >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Descrição</th>
                                                <th>Vencimento</th>
                                                <th>Pagamento</th>
                                                <th>Valor</th>
                                                <th>Valor Pendente</th>
                                                <th>Valor Pago</th>
                                                <th>Situação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.lancamentos.map(data =>

                                                    <tr key={data.id}>
                                                        <td><Link onClick={() => this.handleLancamentoClick(data.id)}> {data.id}</Link></td>
                                                        <td>{data.descricao}</td>

                                                        <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                                                        <td>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td>

                                                        <td><NumberFormat className="text-right d-block" value={parseFloat(data.valor)} prefix="R$ " decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                                        <td>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$ " decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                                                        <td>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$ " decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td>
                                                        <td>{data.datapagamento !== null ? "Pago" : "Pendente"}</td>

                                                    </tr>

                                                )
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th scope="row"> </th>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>

                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                        {/* </>
              :
              <p>{this.state.Mensagem}</p>
            } */}
                    </Container>{/*<!-- /.container -->*/}
                </section>

                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div >
        );
    }
}
export default Lancamento;
