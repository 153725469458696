import React, { Component } from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import PublicRoute from './components/Common/PublicRoute';
import Home from './components/Home';
import Login from './components/Login';
import GenericNotFound from './components/Common/GenericNotFound';
import Lancamentos from './components/Lancamentos';
import Lancamento from './components/Lancamento';
import LancamentoEditar from './components/LancamentoEditar';
import PrivateRoute from './components/Common/PrivateRoute';
import lancamentos from './components/Lancamentos';
import SaldoEditar from './components/SaldoEditar';
import Categorias from './components/Categorias';
import ContasAPagar from './components/ContasAPagar';
import CategoriaRelatorio from './components/CategoriaRelatorio';
import saldos from './components/Saldos';
import contas from './components/contas';
import { Contato } from './utils/config';
// import Plano from './components/Planos';
// import Instrumento from './components/Instrumentos';
// import ImportSinonimo from './components/ImportSinonimo';
// import ImportGlossario from './components/ImportGlossario';
// import ImportInstrumento from './components/ImportInstrumento';
// import sinonimos from './components/sinonimos';
// import politica from './components/politica-privacidade';
// import TermosCondicoes from './components/termos-condicoes';
// import glossario from './components/glossario';
// import EditarInstrumento from './components/EditarInstrumento';
// import DeleteInstrumento from './components/DeleteInstrumento';

// import EditarTermos from './components/EditarTermos';
// import EditarGlossario from './components/EditarGlossario';
// import EditarSinonimos from './components/EditarSinonimo';
// import EditarPolitica from './components/EditarPolitica';
// import Usuario from './components/Usuarios';
// import Codigos from './components/Codigos';
// import GenerateCode from './components/generate-code';
// import Newsletters from './components/Newsletters';
// import { version } from '../package.json';
// import Loading from './loading';
// import EditarPlano from './components/EditarPlano';
class App extends Component {
  render() {
    const isProduction = process.env.NODE_ENV === 'production';
    return (

      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/" component={App}> */}
          {/* <Route exact path="/lancamentos" component={lancamentos} />
            <Route exact path="/editar-lancamento/:id" component={LancamentoEditar} />
            <Route path="/404" component={GenericNotFound} /> */}
          {/* </Route> */}
          {/* PUBLIC ROUTES */}
          <PublicRoute component={Login} path="/login" exact restricted={false} />
          <PrivateRoute component={Home} path="/" exact restricted={false} />
          <PrivateRoute component={LancamentoEditar} path="/editar-lancamento/:id" exact restricted={false} />
          <PrivateRoute component={LancamentoEditar} path="/editar-lancamento/" exact restricted={false} />
          <PrivateRoute component={Lancamentos} path="/lancamentos" exact restricted={false} />
          <PrivateRoute component={Lancamentos} path="/lancamentos/:id" exact restricted={false} />
          <PrivateRoute component={saldos} path="/saldos" exact restricted={false} />
          <PrivateRoute component={contas} path="/contas" exact restricted={false} />
          <PrivateRoute component={SaldoEditar} path="/editar-saldo/:id" exact restricted={false} />
          <PrivateRoute component={Categorias} path="/categorias" exact restricted={false} />
          <PrivateRoute component={ContasAPagar} path="/contas-a-pagar" exact restricted={false} />
          <PrivateRoute component={CategoriaRelatorio} path="/categoria/:id/relatorio" exact restricted={false} />

          <PublicRoute component={GenericNotFound} />
          {/* <PrivateRoute component={Plano} path="/planos" exact restricted={false} /> */}
          {/* <PrivateRoute component={Usuario} path="/usuarios" exact restricted={false} /> */}
          {/* <PrivateRoute component={Codigos} path="/codigos" exact restricted={false} /> */}
          {/* <PrivateRoute component={Instrumento} path="/instrumentos" exact restricted={false} /> */}
          {/* <PrivateRoute component={glossario} path="/glossario" exact restricted={false} />
                <PrivateRoute component={politica} path="/politica" exact restricted={false} />
                <PrivateRoute component={TermosCondicoes} path="/termos" exact restricted={false} />
                <PrivateRoute component={sinonimos} path="/sinonimos" exact restricted={false} />
                <PrivateRoute component={ImportInstrumento} path="/importInstrumento" exact restricted={false} />
                <PrivateRoute component={ImportGlossario} path="/importGlossario" exact restricted={false} />
                <PrivateRoute component={ImportSinonimo} path="/importSinonimo" exact restricted={false} /> */}

          {/* <PrivateRoute component={EditarInstrumento} path="/editar-instrumento/" exact restricted={false} /> */}
          {/* <PrivateRoute component={LancamentoEditar} path="/lancamento" exact restricted={false} /> */}
          {/*  <PrivateRoute component={EditarTermos} path="/editar-termos/:termo" exact restricted={false} />
                <PrivateRoute component={EditarGlossario} path="/editar-glossario/:glossario" exact restricted={false} />
                <PrivateRoute component={EditarPolitica} path="/editar-politica/:politica" exact restricted={false} />
                <PrivateRoute component={EditarSinonimos} path="/editar-sinonimos/:sinonimo" exact restricted={false} />

                <PrivateRoute component={EditarInstrumento} path="/add-instrumento" exact restricted={false} /> */}
          {/* <PrivateRoute component={InserirTermos} path="/add-termos/:termo" exact restricted={false} />
                <PrivateRoute component={InserirGlossario} path="/add-glossario/:glossario" exact restricted={false} />
                <PrivateRoute component={InserirPolitica} path="/add-politica/:politica" exact restricted={false} />
                <PrivateRoute component={InserirSinonimos} path="/add-sinonimos/:sinonimo" exact restricted={false} /> */}

          {/* <PrivateRoute component={DeleteInstrumento} path="/delete-instrumento" exact restricted={false} /> */}
          {/*<PrivateRoute component={ExcluirTermos} path="/excluir-termos/:termo" exact restricted={false} />
                <PrivateRoute component={ExcluirGlossario} path="/excluir-glossario/:glossario" exact restricted={false} />
                <PrivateRoute component={ExcluirPolitica} path="/excluir-politica/:politica" exact restricted={false} />
                <PrivateRoute component={ExcluirSinonimos} path="/excluir-sinonimos/:sinonimo" exact restricted={false} /> */}

          {/* <PrivateRoute component={GenerateCode} path="/generate-code" exact restricted={false} />
                <PrivateRoute component={Newsletters} path="/newsletters" exact restricted={false} /> */}
        </Switch>

      </BrowserRouter>




    )
  }
}

export default App;
